import { mapGetters } from 'vuex'
import item from './item/index.vue'

export default {
  name: 'setting-education-categories-translations',
  data () {
    return {
      currentLocale: ''
    }
  },
  computed: {
    ...mapGetters({
      basicLocales: 'setting/basicLocales'
    })
  },
  components: {
    item
  },
  created () {
    this.currentLocale = this.basicLocales[0].locale
  },
  methods: {}
}
