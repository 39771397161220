import validation from '@/mixins/validation'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'setting-education-categories-countries-item',
  mixin: [validation],
  props: {
    item: {}
  },
  data () {
    return {
      statusId: '',
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      setting: 'courses/setting',
      currentItem: 'settingEducationCategories/currentItem'
    })
  },
  created () {
    this.setData()
  },
  methods: {
    setData () {
      const countryItem = this.currentItem.countries.data.find(e => e.countryId === this.item.id)
      if (countryItem) {
        this.statusId = countryItem.statusId
      }
    },
    changeSelect (item) {
      const formData = new FormData()
      const countryItem = this.currentItem.countries.data.find(e => e.countryId === item.id)
      if (countryItem) {
        formData.append('_method', 'patch')
        formData.append('status_id', this.statusId)
        formData.append('id', countryItem.id)
      } else {
        formData.append('status_id', this.statusId)
        formData.append('country_id', this.item.id)
      }
      formData.append('category_id', this.currentItem.id)
      this.loading = true
      this.update(formData)
        .then(() => {
          this.$toasted.success(this.$t('success_updated'))
        }).finally(() => {
          this.loading = false
        })
    },
    ...mapActions({
      update: 'settingEducationCategories/UPDATE_COUNTRIES'
    })
  }
}
