import { mapActions, mapGetters, mapMutations } from 'vuex'
import SeoForm from '@/components/seo-form/index.vue'

export default {
  name: 'setting-education-categories-seo',
  data () {
    return {}
  },
  computed: {
    ...mapGetters({
      currentItem: 'settingEducationCategories/currentItem',
      seo: 'settingEducationCategories/seo',
      seoLoading: 'settingEducationCategories/seoLoading',
      updateSeoLoading: 'settingEducationCategories/updateSeoLoading',
      basicLocales: 'setting/basicLocales'
    })
  },
  components: {
    SeoForm
  },
  created () {
    if (!this.seo) {
      this.fetchData({
        id: this.currentItem.id
      })
    }

	this.$on('event-save', () =>{
	  this.$refs['seoForm'].$emit('event-submit');
	});
  },
  methods: {
    ...mapActions({
      fetchData: 'settingEducationCategories/GET_SEO',
      update: 'settingEducationCategories/UPDATE_SEO'
    }),
    ...mapMutations({
      setSeo: 'settingEducationCategories/SET_SEO'
    }),
    submit (formData) {
      formData.category_id = this.currentItem.id

	  this.update(formData).then(() => {
        this.$toasted.success(this.$t('success_updated'));
      })
    }
  },
  destroyed () {
    this.setSeo(null)
  }
}
