import mixins from '@/mixins'
import validation from '@/mixins/validation'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import course from '@/mixins/course'
import SlugNameInput from '@/components/slug-name-input/index.vue'
import { snakeCase } from 'lodash'

export default {
  name: 'setting-education-categories-general',
  mixin: [course, mixins, validation],
  data () {
    return {
      valid: false,
      search: null,
      searchInput: null,
      payload: {
        title: '',
        statusId: '',
        parentId: '',
        position: '',
        slug: ''
      }
    }
  },
  watch: {
    currentItem () {
      if (this.currentItem && this.editId) {
        this.setData()
      }
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'settingEducationCategories/isLoading',
      currentItem: 'settingEducationCategories/currentItem',
      setting: 'courses/setting',
      list: 'settingEducationCategories/list',
      editId: 'settingEducationCategories/editId',
    })
  },
  components: {
    SlugNameInput
  },
  created () {
    if (this.currentItem) {
      this.setData()
    }

	this.$on('event-save', () =>{
	  this.submit();
	});
  },
  methods: {
    ...mapActions({
      update: 'settingEducationCategories/UPDATE',
      create: 'settingEducationCategories/CREATE'
    }),
    ...mapMutations({
      setCountryIds: 'profile/SET_COUNTRY_IDS'
    }),
    setData () {
      Object.assign(this.payload, this.currentItem)
    },
    createRequestPayload () {
      const formData = new FormData()

      for (let i in this.payload) {
        formData.append(snakeCase(i), this.payload[i] || '')
      }

      this.currentItem ? formData.append('_method', 'patch') : ''
      return formData
    },
    submit () {
      this.$refs.form.validate();

      if (this.valid) {
        if (this.currentItem) {
          this.update(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('success_updated'));
          })
        } else {
          this.create(this.createRequestPayload()).then(() => {
            this.$toasted.success(this.$t('success_created'));
          })
        }
      }
    }
  }
}
